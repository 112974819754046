/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.drone-timeline-parent {
  display: flex;
}
.drone-history-timeline::v-deep,
.drone-relate-history-timeline::v-deep {
  padding: 16px 16px;
  max-width: 450px;
  width: 50%;
}
.drone-history-timeline::v-deep .timeline-title,
.drone-relate-history-timeline::v-deep .timeline-title {
  font-size: 20px;
  margin-bottom: 5px;
}
.drone-history-timeline::v-deep .related-timeline-title,
.drone-relate-history-timeline::v-deep .related-timeline-title {
  display: flex;
  font-size: 20px;
  margin-bottom: 5px;
}
.drone-history-timeline::v-deep .related-timeline-title .close-icon,
.drone-relate-history-timeline::v-deep .related-timeline-title .close-icon {
  cursor: pointer;
  color: red;
  margin-left: 5px;
}
.drone-history-timeline::v-deep .histories-filter,
.drone-relate-history-timeline::v-deep .histories-filter {
  margin-bottom: 16px;
}
.drone-history-timeline::v-deep .histories-filter label,
.drone-relate-history-timeline::v-deep .histories-filter label {
  margin-left: unset;
}
.mobile .drone-history-timeline::v-deep,
.mobile .drone-relate-history-timeline::v-deep {
  padding: 16px 0;
}
.drone-history-timeline::v-deep .ant-timeline-item-content,
.drone-relate-history-timeline::v-deep .ant-timeline-item-content {
  margin-left: 24px;
}
