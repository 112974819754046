/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.drone-log-history .topic {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  color: #14837B;
}
.drone-log-history .action-date {
  color: #1890ff;
  margin-left: 8px;
  font-size: 0.9em;
  font-weight: normal;
}
.drone-log-history .detail {
  margin-top: 8px;
  margin-left: 16px;
  font-size: 0.95em;
}
