/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.drone-service-refer-history {
  padding: 3px 0;
  background-color: #eeeeee;
  border-radius: 8px;
  text-align: center;
}
