/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.drone-ticket-history .topic {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  color: #f5222d;
}
.drone-ticket-history .action-date {
  color: #1890ff;
  margin-left: 8px;
  font-size: 0.9em;
  font-weight: normal;
}
.drone-ticket-history .detail {
  margin-top: 8px;
  margin-left: 16px;
  font-size: 0.95em;
}
.drone-ticket-history .history-result-detail {
  margin-top: 12px;
  font-size: 0.95em;
  padding: 8px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
}
.drone-ticket-history .history-result-detail > div {
  margin-bottom: 2px;
}
.drone-ticket-history .history-result-detail .title {
  text-decoration: underline;
}
