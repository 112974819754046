/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.info {
  color: #8c8c8c;
  padding-top: 12px;
  font-size: 0.9em;
}
.info-time {
  vertical-align: middle;
}
