/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.drone-service-history .topic {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
}
.drone-service-history .action-date {
  color: #1890ff;
  margin-left: 8px;
  font-size: 0.9em;
  font-weight: normal;
}
.drone-service-history .detail {
  margin-top: 8px;
  margin-left: 16px;
}
.drone-service-history .service-claim {
  margin-top: 16px;
  text-decoration: underline dashed;
}
.drone-service-history .service-jobs {
  margin-bottom: 6px;
  color: #14837B;
  font-size: 14px;
}
.drone-service-history .service-status-list {
  font-size: 0.9em;
}
.drone-service-history .service-status-list label {
  width: 75px;
  display: inline-block;
}
.drone-service-history .service-status-list label::after {
  content: ' :';
}
