/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.drone-comment-ticket-history .topic {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  color: #f5222d;
}
.drone-comment-ticket-history .action-date {
  color: #1890ff;
  margin-left: 8px;
  font-size: 0.9em;
  font-weight: normal;
}
.drone-comment-ticket-history .detail {
  margin-top: 8px;
  margin-left: 16px;
}
.comment-tag {
  font-weight: normal;
  font-family: Helvetica, Arial, sans-serif;
  white-space: nowrap;
  display: inline-block;
  padding: 0 7px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 20px;
  color: white;
  background: #8c8c8c;
}
.ticket-comment-tooltip {
  padding: 2px 4px;
}
.ticket-comment-tooltip .description {
  color: #8c8c8c;
}
