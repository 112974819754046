/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.service-status {
  font-weight: normal;
  font-family: Helvetica, Arial, sans-serif;
  white-space: nowrap;
}
.service-status.service-status-tag {
  display: inline-block;
  padding: 0 7px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 20px;
}
.service-status-tooltip-content {
  padding: 2px 4px;
}
.service-status-tooltip-content .reason {
  border-top: 1px dashed #d9d9d9;
  margin-top: 0;
  padding-top: 6px;
  font-size: 12px;
}
.service-status-new {
  color: #1890ff;
}
.service-status-new.service-status-tag {
  color: #fff;
  background: #1890ff;
}
.service-status-new.service-status-tag:hover {
  opacity: 0.85;
}
.service-status-tooltip-new .description {
  color: #1890ff;
}
.service-status-bg-new {
  background-color: #1890ff;
  padding: 3px 5px;
  border-radius: 5px;
  color: white;
}
.service-status-outline-new {
  border: 1px solid #1890ff;
  padding: 3px 5px;
  border-radius: 5px;
  color: #1890ff;
}
.service-status-in_progress {
  color: #FFC425;
}
.service-status-in_progress.service-status-tag {
  color: #fff;
  background: #FFC425;
}
.service-status-in_progress.service-status-tag:hover {
  opacity: 0.85;
}
.service-status-tooltip-in_progress .description {
  color: #FFC425;
}
.service-status-bg-in_progress {
  background-color: #FFC425;
  padding: 3px 5px;
  border-radius: 5px;
  color: white;
}
.service-status-outline-in_progress {
  border: 1px solid #FFC425;
  padding: 3px 5px;
  border-radius: 5px;
  color: #FFC425;
}
.service-status-pending {
  color: #FFC425;
}
.service-status-pending.service-status-tag {
  color: #fff;
  background: #FFC425;
}
.service-status-pending.service-status-tag:hover {
  opacity: 0.85;
}
.service-status-tooltip-pending .description {
  color: #FFC425;
}
.service-status-bg-pending {
  background-color: #FFC425;
  padding: 3px 5px;
  border-radius: 5px;
  color: white;
}
.service-status-outline-pending {
  border: 1px solid #FFC425;
  padding: 3px 5px;
  border-radius: 5px;
  color: #FFC425;
}
.service-status-pre_delivery {
  color: #F37735;
}
.service-status-pre_delivery.service-status-tag {
  color: #fff;
  background: #F37735;
}
.service-status-pre_delivery.service-status-tag:hover {
  opacity: 0.85;
}
.service-status-tooltip-pre_delivery .description {
  color: #F37735;
}
.service-status-bg-pre_delivery {
  background-color: #F37735;
  padding: 3px 5px;
  border-radius: 5px;
  color: white;
}
.service-status-outline-pre_delivery {
  border: 1px solid #F37735;
  padding: 3px 5px;
  border-radius: 5px;
  color: #F37735;
}
.service-status-wait_delivery {
  color: #F37735;
}
.service-status-wait_delivery.service-status-tag {
  color: #fff;
  background: #F37735;
}
.service-status-wait_delivery.service-status-tag:hover {
  opacity: 0.85;
}
.service-status-tooltip-wait_delivery .description {
  color: #F37735;
}
.service-status-bg-wait_delivery {
  background-color: #F37735;
  padding: 3px 5px;
  border-radius: 5px;
  color: white;
}
.service-status-outline-wait_delivery {
  border: 1px solid #F37735;
  padding: 3px 5px;
  border-radius: 5px;
  color: #F37735;
}
.service-status-close {
  color: #52c41a;
}
.service-status-close.service-status-tag {
  color: #fff;
  background: #52c41a;
}
.service-status-close.service-status-tag:hover {
  opacity: 0.85;
}
.service-status-tooltip-close .description {
  color: #52c41a;
}
.service-status-bg-close {
  background-color: #52c41a;
  padding: 3px 5px;
  border-radius: 5px;
  color: white;
}
.service-status-outline-close {
  border: 1px solid #52c41a;
  padding: 3px 5px;
  border-radius: 5px;
  color: #52c41a;
}
.service-status-cancel {
  color: #000;
}
.service-status-cancel.service-status-tag {
  color: #fff;
  background: #000;
}
.service-status-cancel.service-status-tag:hover {
  opacity: 0.85;
}
.service-status-tooltip-cancel .description {
  color: #000;
}
.service-status-bg-cancel {
  background-color: #000;
  padding: 3px 5px;
  border-radius: 5px;
  color: white;
}
.service-status-outline-cancel {
  border: 1px solid #000;
  padding: 3px 5px;
  border-radius: 5px;
  color: #000;
}
